<script>
export default {
  name: 'LogoHyattZiva',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 70 500 105"
    :aria-labelledby="label"
    role="presentation"
    :class="{ 'fill-current': fill }"
  >
    <g>
      <g>
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M120.5,165.7l0.8,2h2.2l-5-10.8h0l-5,10.8h2.1l0.8-2H120.5z M118.4,160.7l1.5,3.6h-2.9L118.4,160.7z"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="129.7,157.1 129.7,167.7 136.3,167.7 136.3,165.9 131.8,165.9 131.8,157.1 "
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="142.8,157.1 142.8,167.7 149.4,167.7 149.4,165.9 144.9,165.9 144.9,157.1 "
        />
      </g>
      <g>
        <rect
          x="167"
          y="157.1"
          :fill="fill ? '#58595B' : 'currentColor'"
          width="2.1"
          height="10.7"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="185.7,157.1 183.6,157.1 183.6,163.1 176.7,156.9 176.6,156.9 176.6,167.7 178.7,167.7
178.7,161.6 185.7,167.9 "
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M198.7,166c-2.2,0-3.6-1.4-3.6-3.6c0-2.2,1.4-3.6,3.6-3.6c0.8,0,1.7,0.2,2.5,0.6l0.6-1.7
c-1-0.6-2.1-0.8-3.2-0.8c-3.5,0-5.9,2.3-5.9,5.5c0,3.2,2.4,5.5,5.9,5.5c1.1,0,2.4-0.3,3.3-0.9l-0.6-1.7
C200.5,165.7,199.6,166,198.7,166z"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="208.8,157.1 208.8,167.7 215.3,167.7 215.3,165.9 210.9,165.9 210.9,157.1 "
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M230.2,157.1h-2.1v6.2c0,1.8-0.8,2.8-2.4,2.8c-1.5,0-2.4-1-2.4-2.8v-6.2h-2.1v6.4c0,2.9,1.5,4.5,4.4,4.5
c2.9,0,4.5-1.6,4.5-4.5V157.1z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M241.4,161.6c-1.4-0.6-2.1-0.9-2.1-1.8c0-0.5,0.4-1.1,1.6-1.1c0.7,0,1.6,0.4,2.3,0.8l0.5-1.8
c-0.6-0.4-1.7-0.7-2.8-0.7c-2.5,0-3.7,1.5-3.7,3c0,1.4,0.8,2.4,2.5,3c1.6,0.7,2.1,1.1,2.1,1.9c0,0.8-0.6,1.2-1.5,1.2
c-1,0-2.1-0.3-3-0.9l-0.6,1.9c1,0.6,2.4,1,3.5,1c2.6,0,3.7-1.6,3.7-3.2C244,163.5,243.5,162.5,241.4,161.6z"
        />
        <rect
          x="250.9"
          y="157.1"
          :fill="fill ? '#58595B' : 'currentColor'"
          width="2.1"
          height="10.7"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="269.5,157.1 267.2,157.1 264.5,163.5 261.8,157.1 259.3,157.1 264.4,167.8 "
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="282.3,159 282.3,157.1 275.8,157.1 275.8,167.7 282.3,167.7 282.3,165.9 277.9,165.9
277.9,163.2 281.9,163.2 281.9,161.4 277.9,161.4 277.9,159 "
        />
      </g>
      <g>
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M302.7,164.1c0.1,0,0.3,0,0.4,0l0.2,0l1.9,3.7h2.4l-2.5-4.1l0.2-0.1c1.1-0.7,1.8-1.8,1.8-3
c0-2.2-1.6-3.5-4.6-3.5c-1.1,0-2,0-3,0.2v10.6h2.1v-3.7l0.5,0C302.2,164.1,302.5,164.1,302.7,164.1z M301.5,162.4v-3.5l0.2,0
c0.4-0.1,0.6-0.1,1-0.1c1.3,0,2.1,0.7,2.1,1.7c0,1.2-0.8,2-2.3,2c-0.3,0-0.5,0-0.8,0L301.5,162.4z"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="320.7,159 320.7,157.1 314.1,157.1 314.1,167.7 320.7,167.7 320.7,165.9 316.3,165.9
316.3,163.2 320.3,163.2 320.3,161.4 316.3,161.4 316.3,159 "
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M331.9,161.6c-1.4-0.6-2.1-0.9-2.1-1.8c0-0.5,0.4-1.1,1.6-1.1c0.7,0,1.6,0.4,2.3,0.8l0.5-1.8
c-0.6-0.4-1.7-0.7-2.8-0.7c-2.5,0-3.7,1.5-3.7,3c0,1.4,0.8,2.4,2.5,3c1.6,0.7,2.1,1.1,2.1,1.9c0,0.8-0.6,1.2-1.5,1.2
c-1,0-2.1-0.3-3-0.9l-0.6,1.9c1,0.6,2.4,1,3.5,1c2.6,0,3.7-1.6,3.7-3.2C334.5,163.5,334,162.5,331.9,161.6z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M346.3,156.9c-3.3,0-5.5,2.3-5.5,5.5c0,3.2,2.2,5.5,5.4,5.5c3.2,0,5.5-2.3,5.5-5.5
C351.7,159.1,349.5,156.9,346.3,156.9z M346.2,166.1c-1.9,0-3.2-1.5-3.2-3.7c0-2.2,1.3-3.7,3.2-3.7c1.9,0,3.2,1.5,3.2,3.7
C349.4,164.6,348.1,166.1,346.2,166.1z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M362.1,164.1c0.1,0,0.3,0,0.4,0l0.2,0l1.9,3.7h2.4l-2.5-4.1l0.2-0.1c1.1-0.7,1.8-1.8,1.8-3
c0-2.2-1.6-3.5-4.6-3.5c-1.1,0-2,0-3,0.2v10.6h2.1v-3.7l0.5,0C361.7,164.1,361.8,164.1,362.1,164.1z M360.9,162.4v-3.5l0.2,0
c0.4-0.1,0.6-0.1,1-0.1c1.3,0,2.1,0.7,2.1,1.7c0,1.2-0.8,2-2.3,2c-0.3,0-0.5,0-0.8,0L360.9,162.4z"
        />
        <polygon
          :fill="fill ? '#58595B' : 'currentColor'"
          points="381.3,157.1 372.5,157.1 372.5,159 375.9,159 375.9,167.7 378,167.7 378,159 381.3,159 "
        />
      </g>
      <g>
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M178,113.7l14.4-24l-2.8,0c0,0-3.4,0-4.9,2.5c-0.4,0.8-9.4,15.9-9.4,15.9s-8.5-14.3-9.4-15.9
c-1.3-2.3-3.8-2.5-4.8-2.5c-0.1,0-3.1,0-3.1,0l14.4,24v20.6l2,0c1.7,0,3.6-1.3,3.6-3.7V113.7z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M204.6,131c0.1-0.3,2.7-6.6,3.8-9.3l0,0l0.1-0.2h14.3c0,0,3.7,9.4,3.8,9.4c1.2,3.3,4.6,3.4,4.7,3.4l2.3,0
l-17.6-45.7l-18,45.7l2.3,0C201.4,134.3,203.8,133.5,204.6,131z M215.8,103.1l5.1,13.4h-10.4L215.8,103.1z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M315.7,92.9c0-1.6-1.2-3.2-3.2-3.2h-29.3l0,2c0,1.5,1.2,3.2,3.2,3.2h10.2v35.8c0,2.4,1.8,3.7,3.6,3.7h2
V94.8h13.5L315.7,92.9z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M272.5,92.9c0-1.6-1.2-3.2-3.2-3.2H240l0,2c0,1.5,1.2,3.2,3.2,3.2h10.2v35.8c0,2.4,1.8,3.7,3.6,3.7h2
V94.8h13.5L272.5,92.9z"
        />
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M118.9,114.5h18.1l0,16.3c0,2.3,1.8,3.5,3.5,3.5l2,0l0-44.6l-2-0.1c-1.7,0-3.6,1.3-3.6,3.7l0,16.1h-18.1
l0-16.2c0-2.3-1.8-3.5-3.6-3.5l-2,0l0,44.6l1.9,0.1c1.8,0,3.6-1.3,3.6-3.7V114.5z"
        />
        <g>
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M447.2,89.7l-2.3,0c-0.1,0-3.4,0.1-4.5,3.3c-0.1,0.3-10.4,25.6-10.8,26.6l-0.3,0.6l-0.3-0.6
c-1.7-4.4-10.4-26.5-10.5-26.6c-0.9-2.6-3.4-3.3-4.7-3.3l-2.2,0l17.6,45.5L447.2,89.7z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M398.9,89.7l-1.6,0c-1.6,0-3.9,1.1-3.9,3.9v40.8l1.6,0c1.9,0,3.9-1.3,3.9-4.1V89.7z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M456.3,131c0.1-0.3,3.9-9.5,3.9-9.5h14.3c0,0,3.8,9.4,3.8,9.4c1.2,3.3,4.6,3.4,4.7,3.4l2.3,0l-17.7-45.7
l-18.1,45.7l2.3,0C453.1,134.3,455.5,133.6,456.3,131z M467.5,103.1l5.1,13.4h-10.4L467.5,103.1z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M374.3,129.3h-19.9l24.9-39.6h-30.8l0,1.8c0,1.6,1.3,3.3,3.6,3.3h17.8l-25,39.6h33v-1.7
c0-0.1,0-1.5-1.1-2.4C376.2,129.6,375.3,129.3,374.3,129.3z"
          />
        </g>
      </g>
      <path
        :fill="fill ? '#58595B' : 'currentColor'"
        d="M487.7,90.6v3.9h-1v-3.9h-1.5v-0.9h3.9v0.9H487.7z M494.1,94.5l-0.1-2.6l-1.4,1.9h-0.1l-1.4-1.9l0,2.6h-1
l0.2-4.8h0.1l2.1,2.9l2.1-2.9h0.1l0.2,4.8H494.1z"
      />
      <g>
        <path
          :fill="fill ? '#6ECCDE' : 'currentColor'"
          d="M11.6,78.7H9.1c-3,0-3.9,1.4-3.9,3.8c0,2.3,0,6.1,0,6.1L11.6,78.7z"
        />
        <path
          :fill="fill ? '#008481' : 'currentColor'"
          d="M33.2,79.4c0-0.5-0.5-0.7-0.9-0.7h-4c0,0-7.2,11.4-7.1,11.1c3.2-5.1,7.4-7.9,11-9.4
C32.2,80.5,33.2,80.1,33.2,79.4"
        />
        <polygon
          :fill="fill ? '#24ADAE' : 'currentColor'"
          points="17.1,78.7 5.3,97.4 5.3,106.3 22.7,78.7 "
        />
        <path
          :fill="fill ? '#09999B' : 'currentColor'"
          d="M22.7,78.7L5.3,106.3v3.2c3-1.6,6.2-4.2,8.8-8.3c0,0,3.4-5.4,3.4-5.4l10.8-17.1H22.7z"
        />
        <polygon
          :fill="fill ? '#36BFBE' : 'currentColor'"
          points="83.9,149 72,167.7 77.6,167.7 83.9,157.8 "
        />
        <path
          :fill="fill ? '#6ECCDE' : 'currentColor'"
          d="M77.6,167.7H80c3,0,3.9-1.4,3.9-3.8c0-2.3,0-6.1,0-6.1L77.6,167.7z"
        />
        <path
          :fill="fill ? '#008481' : 'currentColor'"
          d="M56,167c0,0.5,0.5,0.7,0.9,0.7h4c0,0,7.2-11.4,7.1-11.1c-3.2,5.1-7.4,7.9-11,9.4C57,166,56,166.3,56,167"
        />
        <polygon
          :fill="fill ? '#24ADAE' : 'currentColor'"
          points="72,167.7 83.9,149 83.9,140.2 66.4,167.7 "
        />
        <path
          :fill="fill ? '#09999B' : 'currentColor'"
          d="M66.4,167.7l17.5-27.6v-3.2c-3,1.6-6.2,4.2-8.8,8.3c0,0-3.4,5.4-3.4,5.4l-10.8,17.1H66.4z"
        />
        <polygon
          :fill="fill ? '#92278F' : 'currentColor'"
          points="53.1,101 47.6,101 33.5,123.2 39,123.2 "
        />
        <polygon
          :fill="fill ? '#AE2375' : 'currentColor'"
          points="44.6,123.2 58.7,101 53.1,101 39,123.2 "
        />
        <polygon
          :fill="fill ? '#EE2A7B' : 'currentColor'"
          points="50.2,123.2 50.2,123.2 64.2,101 58.7,101 44.6,123.2 "
        />
        <polygon
          :fill="fill ? '#C51F67' : 'currentColor'"
          points="50.2,123.2 55.7,123.2 69.8,101 64.2,101 "
        />
        <polygon
          :fill="fill ? '#C51F67' : 'currentColor'"
          points="78.3,78.7 72.8,78.7 58.7,101 64.2,101 "
        />
        <polygon
          :fill="fill ? '#EE2A7B' : 'currentColor'"
          points="64.2,101 69.7,101 69.8,101 83.9,78.7 78.3,78.7 "
        />
        <path
          :fill="fill ? '#B11B29' : 'currentColor'"
          d="M54.6,89.9c-3.3,5.3-7,11.1-7,11.1h5.6l13.2-20.8C62.6,81.6,58,84.4,54.6,89.9"
        />
        <path
          :fill="fill ? '#F04930' : 'currentColor'"
          d="M66.3,80.2L53.1,101h5.5l14.1-22.3C72.8,78.7,69.9,78.8,66.3,80.2"
        />
        <polygon
          :fill="fill ? '#92278F' : 'currentColor'"
          points="36.1,145.5 41.6,145.5 55.7,123.2 50.2,123.2 "
        />
        <polygon
          :fill="fill ? '#AE2375' : 'currentColor'"
          points="44.6,123.2 30.5,145.5 36.1,145.5 50.2,123.2 "
        />
        <polygon
          :fill="fill ? '#EE2A7B' : 'currentColor'"
          points="39,123.2 39,123.2 24.9,145.5 30.5,145.5 44.6,123.2 "
        />
        <polygon
          :fill="fill ? '#C51F67' : 'currentColor'"
          points="39,123.2 33.5,123.2 19.4,145.5 24.9,145.5 "
        />
        <polygon
          :fill="fill ? '#C51F67' : 'currentColor'"
          points="10.8,167.7 16.4,167.7 30.5,145.5 24.9,145.5 "
        />
        <polygon
          :fill="fill ? '#EE2A7B' : 'currentColor'"
          points="24.9,145.5 19.5,145.5 19.4,145.5 5.3,167.7 10.8,167.7 "
        />
        <path
          :fill="fill ? '#B11B29' : 'currentColor'"
          d="M34.6,156.6c3.3-5.3,7-11.1,7-11.1h-5.6l-13.2,20.8C26.6,164.8,31.1,162.1,34.6,156.6"
        />
        <path
          :fill="fill ? '#F04930' : 'currentColor'"
          d="M22.9,166.3l13.2-20.8h-5.5l-14.1,22.3C16.4,167.7,19.3,167.6,22.9,166.3"
        />
        <polygon
          :fill="fill ? '#36BFBE' : 'currentColor'"
          points="11.6,78.7 5.3,88.6 5.3,97.4 17.1,78.7 "
        />
      </g>
    </g>
  </svg>
</template>
